import React, { useState } from 'react';
import '../assets/css/FAQ.css';

function FAQ() {
    const [activeIndex, setActiveIndex] = useState(null);

    const toggle = (index) => {
        setActiveIndex(activeIndex === index ? null : index);
    };

    return (
        <div className='faq'>
            <h1>Häufig gestellte Fragen (FAQ)</h1>

            <div className="faq-item">
                <button className="collapsible" onClick={() => toggle(0)}>
                    1. Was ist die BabyCare App?
                </button>
                <div className={`content ${activeIndex === 0 ? 'show' : ''}`}>
                    <p>Die BabyCare App ist die ultimative Lösung für Eltern wie dich, die eine einfache und umfassende Methode suchen, um die täglichen Bedürfnisse und Entwicklungen deines Babys zu verwalten. Sie bietet dir Werkzeuge zur Nachverfolgung von Fütterungen, Geburtstagsdaten, Entwicklungsmeilensteinen, Gesundheits- und Pflegeeinträgen sowie zur Einrichtung von Erinnerungen und Benachrichtigungen.</p>
                </div>
            </div>

            <div className="faq-item">
                <button className="collapsible" onClick={() => toggle(1)}>
                    2. Wie lade ich die BabyCare App herunter?
                </button>
                <div className={`content ${activeIndex === 1 ? 'show' : ''}`}>
                    <p>Die BabyCare App ist im Google Play Store für Android-Geräte und im Apple App Store für iOS-Geräte verfügbar. Suche einfach nach „BabyCare App“ und klicke auf „Installieren“, um die App herunterzuladen und zu installieren.</p>
                </div>
            </div>

            <div className="faq-item">
                <button className="collapsible" onClick={() => toggle(2)}>
                    3. Ist die BabyCare App kostenlos?
                </button>
                <div className={`content ${activeIndex === 2 ? 'show' : ''}`}>
                    <p>Die BabyCare App kann gratis heruntergeladen werden. Die Basisfunktionen sind kostenlos, während zusätzliche Funktionen durch In-App-Käufe oder ein Abonnement verfügbar sind.</p>
                </div>
            </div>

            <div className="faq-item">
                <button className="collapsible" onClick={() => toggle(3)}>
                    4. Wie registriere ich mich bei der BabyCare App?
                </button>
                <div className={`content ${activeIndex === 3 ? 'show' : ''}`}>
                    <p>Öffne die App und klicke auf „Registrieren“. Gib deine E-Mail-Adresse und ein Passwort ein, um ein Konto zu erstellen. Du erhältst eine Bestätigungs-E-Mail, um deine Registrierung abzuschließen.</p>
                </div>
            </div>

            <div className="faq-item">
                <button className="collapsible" onClick={() => toggle(4)}>
                    5. Wie melde ich mich bei der BabyCare App an?
                </button>
                <div className={`content ${activeIndex === 4 ? 'show' : ''}`}>
                    <p>Starte die App. Gib deine registrierte E-Mail-Adresse und dein Passwort ein, um dich bei deinem Konto anzumelden.</p>
                </div>
            </div>

            <div className="faq-item">
                <button className="collapsible" onClick={() => toggle(5)}>
                    6. Wie kann ich mein Passwort zurücksetzen?
                </button>
                <div className={`content ${activeIndex === 5 ? 'show' : ''}`}>
                    <p>Falls du dein Passwort vergessen hast, klicke auf der Anmeldeseite auf „Passwort vergessen?“ und folge den Anweisungen, um ein neues Passwort zu erstellen. Du erhältst eine E-Mail mit einem Link zum Zurücksetzen deines Passworts.</p>
                </div>
            </div>

            <div className="faq-item">
                <button className="collapsible" onClick={() => toggle(6)}>
                    7. Wie kann ich mein Benutzerprofil bearbeiten?
                </button>
                <div className={`content ${activeIndex === 6 ? 'show' : ''}`}>
                    <p>Gehe zu den Einstellungen in der App und wähle „Benutzeraccount“. Hier kannst du deine persönlichen Daten aktualisieren, einschließlich Name, Adresse und weitere Informationen.</p>
                </div>
            </div>

            <div className="faq-item">
                <button className="collapsible" onClick={() => toggle(7)}>
                    8. Wie kann ich meine Daten in der App sichern?
                </button>
                <div className={`content ${activeIndex === 7 ? 'show' : ''}`}>
                    <p>Die BabyCare App speichert deine Daten sicher in der Cloud, sodass du keine wertvollen Informationen verlierst, selbst wenn du das Gerät wechselst. Deine Daten werden regelmäßig gesichert.</p>
                </div>
            </div>

            <div className="faq-item">
                <button className="collapsible" onClick={() => toggle(8)}>
                    9. Wie kann ich meine Daten löschen?
                </button>
                <div className={`content ${activeIndex === 8 ? 'show' : ''}`}>
                    <p>Wenn du dein Konto und alle damit verbundenen Daten löschen möchtest, gehe zu den Einstellungen in der App und wähle „Konto löschen“. Befolge die Anweisungen, um dein Konto dauerhaft zu entfernen. Beachte bitte, dass dies nicht rückgängig gemacht werden kann.</p>
                </div>
            </div>

            <div className="faq-item">
                <button className="collapsible" onClick={() => toggle(9)}>
                10. Wie kann ich den Kundenservice kontaktieren?
                </button>
                <div className={`content ${activeIndex === 9 ? 'show' : ''}`}>
                    <p>Bei Fragen oder Problemen kannst du uns jederzeit per E-Mail unter support@mybabybuddy.com erreichen. Alternativ kannst du uns auch über das Kontaktformular auf unserer Website https://mybabybuddy.com kontaktieren.</p>
                </div>
            </div>

            <div className="faq-item">
                <button className="collapsible" onClick={() => toggle(10)}>
                11. Welche Datenschutzmaßnahmen sind in [Name der App] integriert?
                </button>
                <div className={`content ${activeIndex === 10 ? 'show' : ''}`}>
                    <p>Wir legen großen Wert auf deine Privatsphäre. Alle Daten werden sicher gespeichert und sind nur für dich zugänglich. Die BabyCare App verwendet moderne Sicherheitsmaßnahmen, um deine Informationen zu schützen. Weitere Informationen findest du in unserer <a href="https://mybabybuddy.com/privacypolicy">Datenschutzerklärung</a>.</p>
                </div>
            </div>

            <div className="faq-item">
                <button className="collapsible" onClick={() => toggle(11)}>
                12. Welche Geräte werden von [Name der App] unterstützt?
                </button>
                <div className={`content ${activeIndex === 11 ? 'show' : ''}`}>
                    <p>Die BabyCare App ist für Android-Geräte mit Android [Version] und iOS-Geräte mit iOS [Version] oder höher verfügbar. Überprüfe die App-Store-Seite für detaillierte Systemanforderungen.</p>
                </div>
            </div>

            <div className="faq-item">
                <button className="collapsible" onClick={() => toggle(12)}>
                13. Wie kann ich Feedback zur BabyCare App geben?
                </button>
                <div className={`content ${activeIndex === 12 ? 'show' : ''}`}>
                    <p>Wir freuen uns über dein Feedback! Du kannst uns direkt über die App unter „Feedback“ kontaktieren oder uns eine E-Mail an feedback@mybabybuddy.com senden.</p>
                </div>
            </div>

            <div className="faq-item">
                <button className="collapsible" onClick={() => toggle(13)}>
                14. Was mache ich, wenn ich auf ein technisches Problem stoße?
                </button>
                <div className={`content ${activeIndex === 13 ? 'show' : ''}`}>
                    <p>Sollten technische Probleme auftreten, versuche zunächst, die App oder dein Gerät neu zu starten. Wenn das Problem weiterhin besteht, wende dich bitte an unseren Support unter support@mybabybuddy.com und beschreibe das Problem so detailliert wie möglich.</p>
                </div>
            </div>

            <div className="faq-item">
                <button className="collapsible" onClick={() => toggle(14)}>
                15. Wie werde ich über Updates und neue Funktionen informiert?
                </button>
                <div className={`content ${activeIndex === 14 ? 'show' : ''}`}>
                    <p>Du erhältst Benachrichtigungen über Updates direkt in der App. Außerdem kannst du unsere Website https://mybabybuddy.com besuchen und unseren Newsletter abonnieren, um die neuesten Informationen zu erhalten.</p>
                </div>
            </div>
        </div>
    );
}

export default FAQ;
