import React from 'react';

function Footer() {
  return (
    <footer>
      <ul>
        <li>
          <a href="/imprint">Impressum</a>
        </li>
        <li>
          <a href="/privacypolicy">Datenschutz</a>
        </li>
      </ul>
      <p>© 2024 mybabybuddy.com. Alle Rechte vorbehalten.</p>
    </footer>
  );
}

export default Footer;
