import React from 'react';
import Brand from '../assets/img/logo.png';

function Header() {
    return (
        <header className="App-header">
            <a href='/'><img className="brand_logo" src={Brand} alt="mybabybuddy.com Logo" /></a>
            <ul>
                <li>
                    <a href='/'>Home</a>
                </li>
                <li>
                    <a href='/about'>Über Uns</a>
                </li>
                <li>
                    <a href='/contact'>Kontakt</a>
                </li>
                <li>
                    <a href='/faq'>FAQ</a>
                </li>
            </ul>
        </header>
    );
}

export default Header;
