import React from 'react';

function About() {
    return (
        <div className='privacy'>
            <h1>Herzlich Willkommen auf der Webseite von babybuddy.com</h1>
            <p>Die Seite befindet sich aktuell im Aufbau!</p>
        </div>
    );
}

export default About;